<template>
    <el-main>
        <el-form ref="form" :model="form" label-width="100px">
            <div v-for="(item, index) in list" :key="index" class="item">
                <div style="flex: 1;">
                    <el-form-item label="客服昵称" required>
                        <el-input v-model="item.name"></el-input>
                    </el-form-item>
                    <el-form-item label="客服电话" required>
                        <el-input v-model="item.mobile"></el-input>
                    </el-form-item>
                </div>
                <el-button type="text" style="margin-left: 20px;transform: translateY(-10px);"
                    :style="{ opacity: index > 0 ? 1 : 0 }" @click="list.splice(index, 1)">删除</el-button>
            </div>
        </el-form>
        <el-button type="text" @click="list.push({ name: '', mobile: '' })">+ 添加客服</el-button>
        <Preservation @preservation="onSubmit"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
    components: {
        Preservation,
    },
    data () {
        return {
            list: [],
            dialogVisible: false,
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getList () {
            this.$axios
                .post(this.$api.repair.set.kfList)
                .then(res => {
                    if (res.code == 0) {
                        this.list = res.result.contend_d
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onSubmit () {
            let list = [...this.list]
            if (list.some(item => !item.name || !item.mobile)) {
                return this.$message.warning('请将表单填写完整');
            }
            this.$axios
                .post(this.$api.repair.set.kfAdd, { content: list })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('保存成功');
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .item {
        padding: 22px 30px 0px;
        background-color: #ebf0f3;
        overflow: hidden;
        display: flex;
        align-items: center;

        &+.item {
            margin-top: 20px;
        }
    }
}
</style>
